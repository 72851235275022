body {
  font-family: 'Times New Roman', sans-serif;
}
.background {
  background-color: #DBE9F4;
}

.text-color {
  color: #305F87;
}

.nav-text {
  color: #B1D4E0;
}

.navbar-bg {
  background-color: #305F87;
}

.card-img {
  transition: transform 0.3s ease;
}

.card:hover .card-img {
  transform: scale(1.1);
}

.profile-image {
  width: 200px;
  height: auto;
  margin: 30px auto;
}

.custom-border {
  border-left: 2px solid #305F87;
}

.indent {
  display: inline-block;
  padding-left: 1em;
  text-indent: -1em;
}

.link-text {
  color: #B1D4E0;
}

.navbar-nav .nav-link.link-text.active,
.navbar-nav .nav-link.link-text:focus {
  color: #B1D4E0 !important;
}

.nav-link {
  color: black !important;
}
.card-wrapper {
  position: relative;
  display: inline-block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-color: rgba(48, 95, 135, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card-wrapper:hover .overlay {
  opacity: 1;
}

.languages {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 0 0.5rem; /* Added padding */
}

.languages span {
  padding: 0.25rem 0.5rem;
  background-color: white;
  color: black;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  max-width: 150px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-name {
  color: #305F87;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  text-align: center;
}
